.field__container{
  width: 100%;
  height: 66px;
  display: block;
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 0.2778vw 2.0833vw 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  font-size: 20px;
}

.field__label{
  min-width: 70px;
  font-size: 20px;
  font-weight: bold;
}

.field__container:focus{
  outline: 0;
  box-shadow: 0px 0.2778vw 2.0833vw 0px rgba(211, 140, 255, 0.5);
}

@media (max-width: 743px) {
  .field__container {
    width: 100%;
    height: 50px;
    font-size: 14px;
  }

  .field__label{
    font-size: 16px;
  }
}