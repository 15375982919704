.tag{
  display: inline-block;
  margin: 5px;
  padding: 5px;
  background-color: #D38CFF;
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 18px;
};

.tag:hover{
  cursor: pointer;
}

.tag__container {
  width: 100% !important;
  max-height: 90px;
  border: 1px solid rgba(0,0,0,0.2);
  padding: 5px 10px;
  border-radius: 10px;
  overflow: auto;
}

@media (max-width: 743px){
  .tag{
    margin: 3px;
    padding: 3px;
    font-size: 10px;
  }

  .tag__container{
    max-height: 60px;
  }

  .tag__container input{
    display: inline;
    font-size: 10px;
  }
}